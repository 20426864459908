import React from 'react'
import { LinearProgress } from '@mui/material';

// const ResultSectionProgressBars = ({ subscores }) => {

const titles = [
  // reach new people
  {
    id: 1, title: "Reach New People",
    subheader: "It looks like you are passionate about reaching new people in your community and growing your church's average weekly attendance!"
  },

  // increase volunteers
  {
    id: 2, title: "Increase Volunteers",
    subheader: "It looks like you are focused on adding more volunteers to serve and grow in the ministry God has called your church to!"
  },

  // refresh approach
  {
    id: 3, title: "Refresh Approach",
    subheader: "It looks like you are ready to challenge old patterns and actively change the way your church has been doing things!"
  },

  // develop leaders
  {
    id: 4, title: "Develop Leaders",
    subheader: "It looks like you are passionate about growing new leaders and helping them develop their unique leadership skills on the journey toward their mission!"
  },

  // engage members
  {
    id: 5, title: "Engage Members",
    subheader: "It looks like you are passionate about engaging members and making them feel included so they can become even more connected to your church!"
  },

  // rediscover mission
  {
    id: 6, title: "Rediscover Mission",
    subheader: "It looks like you are all geared up to communicate and clarify your church's vision to help your team and congregation rediscover their true mission!"
  },

  // organize systems
  {
    id: 7, title: "Organize Systems",
    subheader: "It looks like you have been working with the limited systems you currently have, but are ready to put additional systems/processes in place to get even more organized!"
  },

  // disciple people
  {
    id: 8, title: "Disciple People",
    subheader: "It looks like you are focused on laying out a clear process to help your congregants grow in their faith and stay encouraged in ministry!"
  },

  // increase giving
  {
    id: 9, title: "Increase Giving",
    subheader: "It looks like you are focused on accelerating a culture of giving and stewardship to help your church take the next step toward its mission."
  },
];

const ResultSectionProgressBars = (props) => {

  let htmlContainsPDFClass = props?.pdfClass;

  // const { subScore1, subScore2, subScore3, subScore4, subScore5, subScore6, subScore7, subScore8, subScore9 } = props?.subscores;
  // console.log(props.subscores);

  // console.log(subScore1, subScore2, subScore3, subScore4, subScore5, subScore6, subScore7, subScore8, subScore9);

  // console.log(props);
  const unsortedSubscores = Object.values(props?.subscores);
  console.log(unsortedSubscores.forEach(val => console.log(typeof val, val)));
  // console.log(unsortedSubscores);

  const unsortedSubscoresWithTitles = titles.map((item, index) => {
    // console.log("log i", index, item);
    return {
      ...item,
      subscore: unsortedSubscores[index]
    }
  });

  // console.log(unsortedSubscoresWithTitles);

  const sortedSubscoresWithTitles = unsortedSubscoresWithTitles.sort((a, b) => b?.subscore - a?.subscore);

  // console.log(sortedSubscoresWithTitles);

  const calcLeft = (subscore) => {
    return subscore === 100 ? (subscore - 61) : subscore < 15 ? (-42) : (subscore - 60);
  }

  return (
    <div className="result-container">

      <h2 className="result-title">{sortedSubscoresWithTitles[0]?.title}</h2>
      <p className="result-info">{sortedSubscoresWithTitles[0]?.subheader}</p>
      <div className="result-sections non-pdf-result-sections">
        {/* {JSON.stringify(sortedSubscoresWithTitles)} */}
        {sortedSubscoresWithTitles?.map((item, index) => {
          return (
            <div className={`score-section ${index === 0 ? "first-result-title" : ""}`} key={index}>
              <div className="result-section-title"><p><span>{index + 1}.</span> &nbsp;&nbsp;&nbsp;{item?.title}</p></div>
              <div className="score-progress-bar">

                <LinearProgress variant="determinate" value={item?.subscore} />
                <span style={{ position: "relative", textAlign: "left", left: `${calcLeft(item?.subscore)}%`, top: "-27px", margin: 0, zIndex: 999999, fontWeight: 700, fontSize: "1rem", color: "white" }}>{item?.subscore}%</span>

              </div>
            </div>
          )
        })}
      </div>

      <div className="result-sections pdf-result-sections">
        {sortedSubscoresWithTitles?.map((item, index) => {
            
          return (
            <div className={`score-section ${index === 0 ? "first-result-title" : ""}`} key={index}>
              <div className="result-section-title"><p><span>{index + 1}.</span> &nbsp;&nbsp;&nbsp;{item?.title}</p></div>
              <div className="score-progress-bar">

                <div className="custom-pdf-bar" value={item?.subscore}>
                  <div className="custom-pdf-bar-progress" 
                    style={{ width: `${item?.subscore}%` }}>

                    <p className="score-inside-progress-bar" 
                      style={{ textAlign: "right", width: `calc(100% - 8px)`, 
                      margin: 0, padding: "0px 0px 0.25rem 0px", zIndex: 999999, fontWeight: 700, fontSize: "0.925rem", color: "white" }}>{item?.subscore}%
                    </p>

                  </div>
                </div>
                
              </div>              
            </div>
          )
        })}
      </div>
    </div>
  )
}

export default ResultSectionProgressBars