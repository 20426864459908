import React from 'react';
import { Fragment } from 'react';
import { Link } from "react-router-dom";
import useWindowDimensions from '../../Hooks/useWindowDimensions';
// import Logo from '../../Assets/Images/logo.svg';
import Logo from '../../Assets/Images/logocf.png';
// import churchOutreachLogo from '../../Assets/Images/logo-outreach.png';

const Header = props => {

  const { windowWidth } = useWindowDimensions();
  
  return <Fragment>
    <header>
      {/* <Link to="/start" className="logo"><img src={Logo} /></Link> */}
      <div className="assessment-title" id="assessment-title">
        <img src={Logo} alt="churchfuel" />
        { windowWidth > 650 ? <p>The Church Fuel Assessment</p> : <p>The Church Fuel<br/>Assessment</p>}
      </div>
    </header>
  </Fragment>
}

export default Header;
