import { CircularProgress } from "@mui/material";
import { useEffect, useState } from "react";
import { useLocation, useNavigate, useParams } from "react-router-dom";


const Experiment = () => {

  const location          = useLocation();
  const navigate          = useNavigate();
  // console.log("location", location);

  useEffect(() => {
    const timer = setTimeout(() => {
      navigate("/", { replace: true });
    }, 100);
      return () => clearTimeout(timer);
  }, [navigate]);
  
  // const emailRegex = /[a-z0-9!#$%&'*+/=?^_`{|}~-]+(?:\.[a-z0-9!#$%&'*+/=?^_`{|}~-]+)*@(?:[a-z0-9](?:[a-z0-9-]*[a-z0-9])?\.)+[a-z0-9](?:[a-z0-9-]*[a-z0-9])?/;
  // const getEmail = () => {
  //   // console.log(location.search.split("source=")[1]);
  //   let email = location?.search?.split("source=")?.[1];
  //   console.log("emailRegex.test(email)", emailRegex.test(email));
  //   if(emailRegex.test(email)) {
  //     return email;
  //   }
  // }
  // let email = getEmail();
  let value = location?.search?.split("source=")[1]?.split("&")?.[0];
  // console.log("value", value);
  localStorage.setItem("source", value);
  console.log("localStorage", localStorage.getItem("source"));
  
  // navigate("/", { replace: true });

  return (
    <div style={{ maxWidth: "1100px", margin: "0 auto", minHeight: "55vh", textAlign: "center", display: "flex", flexDirection: "column", justifyContent: "center", alignItems: "center", marginBottom: "60px", backgroundColor: "#ffffff", borderRadius: "10px" }}>

      <CircularProgress style={{ color: "#F7D54D" }} size={60} />
    </div>
  )
}



export default Experiment;
