import React, { useRef, useState } from 'react';

const Video = ({ vidsrcURL, thumbnail, playButton }) => {

  const [playingVideo, setPlayingVideo] = useState(false);
  const [startPlaying, setStartPlaying] = useState(false);

  const vidRef = useRef(null);

  const playVideo = (e) => {
    console.log(vidRef.current);
    vidRef.current.play();
    setPlayingVideo(true);
    if (!startPlaying) {
      setStartPlaying(true);
    }
  }

  const pauseVideo = (e) => {
    console.log(vidRef.current);
    vidRef.current.pause();
    setPlayingVideo(false);
  }

  const showThumbnail = () => {
    if (playingVideo) {
      return <img className="thumbnail" src={thumbnail} style={{ opacity: '0' }} onClick={(e) => pauseVideo(e)} alt="video thumbnail" />;
    } else {
      if (startPlaying) {
        return null
      } else {
        return <img className="thumbnail" src={thumbnail} alt="video thumbnail" />
      }
    }
  }

  const showPlayButton = () => {
    if (playingVideo) {
      return null
    } else {
      return <img className="playbtn" src={playButton} onClick={(e) => playVideo(e)} alt="video play button icon" />
    }
  }

  return (
    <div className="video">
      <video ref={vidRef}>
        <source src={vidsrcURL} type="video/mp4" />
      </video>
      {showThumbnail()}
      {showPlayButton()}
    </div>
  )
}

export default Video;
