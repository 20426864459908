import React from 'react'
import { Link, useParams } from "react-router-dom";
import Button from '@mui/material/Button';
import insightsIcon from '../Assets/Images/insights-icon.png';
import alignmentIcon from '../Assets/Images/alignment-icon.png';
import directionIcon from '../Assets/Images/direction-icon.png';
import Testimonial from './Testimonial';

const InviteInfo = (props) => {

  const { id } = useParams();

  return (
    <div className="invite-container">
      <div className="invite-container-spacer">
        <h3>Invite your team to take this test</h3>
        <p>If you and your team don't agree on the greatest opportunity, it will be difficult to align around any solution. Invite your team to take this assessment and visualize everyone's results on an easy-to-understand dashboard.</p>

        <div className="invite-reasons">
          <div className="reason">
            <img src={insightsIcon} alt="insights icon" /><br />
            <div className="height30"></div>
            <span>Analyze</span><br />
            Discover which opportunity should be your top priority
          </div>

          <div className="reason">
            <img src={alignmentIcon} alt="alignment icon" /><br />
            <div className="height30"></div>
            <span>Align</span><br />
            Align around a plan to prioritize this opportunity as a team
          </div>

          <div className="reason">
            <img src={directionIcon} alt="direction icon" /><br />
            <div className="height30"></div>
            <span>Advance</span><br />
            Ensure your church remains focused on its top priority
          </div>

        </div>

        <div className="cta-btn">
          <Link to={"/invite-team/" + id} target="_blank">
            <Button>INVITE MY TEAM</Button>
          </Link>
        </div>

        <Testimonial />


      </div>
      
      <div className="testimonial-invite">
        <p>Invite your team to take this test along with you and begin to align around your church's top ministry priority!</p>
        <div className="cta-btn">
          <Link to={"/invite-team/" + id} target="_blank">
            <Button>INVITE MY TEAM</Button>
          </Link>
        </div>
      </div>
      
    </div>
  );
}

export default InviteInfo;
