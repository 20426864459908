import { useState, useEffect } from 'react';
import { LinearProgress } from '@mui/material';
import UserIcon from '../../Assets/Images/users.svg';

const titles = [
  {
    id: 1, title: "Reach New People",
    subheader: "It looks like you are passionate about reaching new people in your community and growing your church's average weekly attendance!"
  },
  {
    id: 2, title: "Increase Volunteers",
    subheader: "It looks like you are focused on adding more volunteers to serve and grow in the ministry God has called your church to!"
  },
  {
    id: 3, title: "Refresh Approach",
    subheader: "It looks like you are ready to challenge old patterns and actively change the way your church has been doing things!"
  },
  {
    id: 4, title: "Develop Leaders",
    subheader: "It looks like you are passionate about growing new leaders and helping them develop their unique leadership skills on the journey toward their mission!"
  },
  {
    id: 5, title: "Engage Members",
    subheader: "It looks like you are passionate about engaging members and making them feel included so they can become even more connected to your church!"
  },
  {
    id: 6, title: "Rediscover Mission",
    subheader: "It looks like you are all geared up to communicate and clarify your church's vision to help your team and congregation rediscover their true mission!"
  },
  {
    id: 7, title: "Organize Systems",
    subheader: "It looks like you have been working with the limited systems you currently have, but are ready to put additional systems/processes in place to get even more organized!"
  },
  {
    id: 8, title: "Disciple People",
    subheader: "It looks like you are focused on laying out a clear process to help your congregants grow in their faith and stay encouraged in ministry!"
  },
  {
    id: 9, title: "Increase Giving",
    subheader: "It looks like you are focused on accelerating a culture of giving and stewardship to help your church take the next step toward its mission."
  },
];

const DashboardSectionProgressBars = (props) => {

  // const { subScore1, subScore2, subScore3, subScore4, subScore5, subScore6, subScore7, subScore8, subScore9 } = props?.subscores;
  // console.log(props.subscores);

  // console.log(subScore1, subScore2, subScore3, subScore4, subScore5, subScore6, subScore7, subScore8, subScore9);

  const hasWindow = typeof window !== 'undefined';
  
  const getWindowDimensions = () => {
    const width = hasWindow ? window.innerWidth : null;
    // const height = hasWindow ? window.innerHeight : null;
    return { width };
  }
  
  const [windowDimensions, setWindowDimensions] = useState(getWindowDimensions());

  let htmlContainsPDFClass = props?.pdfClass;
  
  useEffect(() => {
  
    if (hasWindow) {
      setWindowDimensions(getWindowDimensions());
      function handleResize() {
        setWindowDimensions(getWindowDimensions());
      }

      window.addEventListener('resize', handleResize);
      return () => window.removeEventListener('resize', handleResize);
    }
  }, [hasWindow]);
  
  // console.log(windowDimensions);
  // console.log(props);
  const unsortedSubscores = Object.values(props?.subscores);
  // console.log(unsortedSubscores);

  const unsortedSubscoresWithTitles = titles.map((item, index) => {
    // console.log("log i", index, item);
    return {
      ...item,
      subscore: unsortedSubscores[index]
    }
  });

  // console.log(unsortedSubscoresWithTitles);

  const sortedSubscoresWithTitles = unsortedSubscoresWithTitles.sort((a, b) => b?.subscore - a?.subscore);

  // console.log(sortedSubscoresWithTitles);

  const calcLeft = (subscore) => {
    return subscore === 100 ? (subscore - 61) : subscore < 15 ? (-42) : (subscore - 60);
  }

  // get maximum number of totalUsers for minWidth of member icons (div.user-amount) 
  const getMaxUsers = () => {
    let getTotalUsers = props?.sections?.map(section => typeof section?.totalUsers === "number" ? section?.totalUsers : 0);
    let maxTotalUsers = getTotalUsers?.sort((a, b) => b - a)[0];
    return maxTotalUsers;
  }

  let totalUsers = null;

  const getUsers = (section, num = null) => {
    let currentSection      = props.sections.find(function(obj) { return obj.order_no == section.id; });

    totalUsers              = currentSection?.totalUsers;
    // totalUsers              = 2;
    let users               = [];
    if(currentSection?.totalUsers) {
      let uCount            = 0;
      // for(let u = 0; u < currentSection.totalUsers; u++ ) {
      for(let u = 0; u < totalUsers; u++ ) {
        if (num) {
          users = [<img src={UserIcon} alt="user icon" />]; 
        } else {
          users.push(<img src={UserIcon} alt="user icon" />);
        }
        uCount++;
      }
      users.push(<div className="number">{uCount}</div>);
      return users;
    }
    return null;
  }
  

  return (
    <div className="bar-container">
      <h1>Your team's top ministry priority is...</h1>
      <h1 className="result-title">{sortedSubscoresWithTitles[0]?.title}</h1>
      <p className="result-info">{sortedSubscoresWithTitles[0]?.subheader}</p>

      <div className="result-sections non-pdf-result-sections">
        {sortedSubscoresWithTitles?.map((item, index) => {
          
          let showUsersOrIcon = null;
          if(htmlContainsPDFClass || windowDimensions.width <= 1100) {
            showUsersOrIcon = getUsers(item, 1);
          } else showUsersOrIcon = getUsers(item);
          // windowDimensions.width <= 1100 ? showUsersOrIcon = getUsers(item, 1) : showUsersOrIcon = getUsers(item);
          
          return (
            <div className={`score-section ${index === 0 ? "first-result-title" : ""}`} key={index}>
              <div className="result-section-title"><p><span>{index + 1}.</span> &nbsp;&nbsp;&nbsp;{item?.title}</p></div>
              <div className="score-progress-bar">

                <LinearProgress variant="determinate" value={item?.subscore} />
                <span className="score-inside-progress-bar" style={{ position: "relative", textAlign: "left", left: `${calcLeft(item?.subscore)}%`, top: "-27px", margin: 0, zIndex: 999999, fontWeight: 700, fontSize: "1rem", color: "white" }}>{item?.subscore}%</span>
                
              </div>
              
              <div className="user-amount" style={ windowDimensions.width <= 1100 ? { minWidth: "70px" } : { minWidth: `${(getMaxUsers() + 1) * 35}px`} }>{ showUsersOrIcon }</div>
              
            </div>
          )
        })}
      </div>


      <div className="result-sections pdf-result-sections">
        {sortedSubscoresWithTitles?.map((item, index) => {
            
          let showUsersOrIcon = null;
          showUsersOrIcon = getUsers(item, 1);
          
          return (
            <div className={`score-section ${index === 0 ? "first-result-title" : ""}`} key={index}>
              <div className="result-section-title"><p><span>{index + 1}.</span> &nbsp;&nbsp;&nbsp;{item?.title}</p></div>
              <div className="score-progress-bar">

                <div className="custom-pdf-bar" value={item?.subscore}>
                  <div className="custom-pdf-bar-progress" 
                    style={{ width: `${item?.subscore}%` }}>

                    <p className="score-inside-progress-bar" 
                      style={{ textAlign: "right", width: `calc(100% - 8px)`, 
                      margin: 0, padding: "0px 0px 0.25rem 0px", zIndex: 999999, fontWeight: 700, fontSize: "0.925rem", color: "white" }}>{item?.subscore}%
                    </p>

                  </div>
                </div>
                
              </div>
              
              <div className="user-amount" style={ windowDimensions.width <= 1100 ? { minWidth: "70px" } : { minWidth: `${(getMaxUsers() + 1) * 35}px`} }>{ showUsersOrIcon }</div>
              
            </div>
          )
        })}
      </div>
    </div>
  )
}

export default DashboardSectionProgressBars;
