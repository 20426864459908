import React from 'react';
import { Fragment } from 'react';
// import BleatLogo from '../../Assets/Images/logo-bleat.svg';
import BleatLogo from '../../Assets/Images/logo-bleat-new.png';
import ChurchFuelLogo from '../../Assets/Images/logo-churchfuel.png';

const Footer = props => {
  return <Fragment>
    <footer>
      <div className="footer-logos-div">
        <div className="footer-logo-left">
          <img src={ChurchFuelLogo} alt="Church Fuel Logo" />
        </div>
        <div className="footer-logo-right">
          <img src={BleatLogo} alt="Bleat Logo" />
        </div>
      </div>
    </footer>
  </Fragment>
}

export default Footer;