import React, { useState } from 'react'
import { Link, useSearchParams } from 'react-router-dom'

const tilesInfoArr = [
  { id: 1, title: "Reach New People", content: "Is reaching out to the unchurched in your community your church's top priority?" },
  { id: 2, title: "Increase Volunteers", content: "Do you need to encourage more congregants to volunteer at church?" },
  { id: 3, title: "Refresh Approach", content: "Do you need to prioritize changing the way your church has done things so far?" },
  { id: 4, title: "Develop Leaders", content: "Is raising up a new generation of leaders your church's top priority?" },
  { id: 5, title: "Engage Members", content: "Is building a stronger connection with congregants your church's top priority?" },
  { id: 6, title: "Rediscover Mission", content: "Do you need to focus on helping your church rediscover their true mission?" },
  { id: 7, title: "Organize Systems", content: "Is it time to shift your focus to putting better organizational systems in place?" },
  { id: 8, title: "Disciple People", content: "Do you need to focus on helping congregants grow in their faith journey?" },
  { id: 9, title: "Increase Giving", content: "Do you need to prioritize the culture of generosity in your church?" },
]


const HowItWorks = () => {

  const [searchParams, setSearchParams] = useSearchParams();
  const [clickedTileContent, setClickedTileContent] = useState({ title: "", content: "" })
  const [activeTile, setActiveTile] = useState({ active: null })
  const [info, setInfo] = useState(true);
  searchParams.get("leader");

  const assessmentLink = () => {
    if (searchParams == "") {
      return "/assessment";
    } else {
      return "/assessment?" + searchParams;
    }
  }

  // handle tile click to show active tile and related content 
  const handleTileClick = (props) => {
    setClickedTileContent({ title: props?.title, content: props?.content })
    setActiveTile({ active: props?.id })
  }

  const text = (<><p className="desktop-only">With multiple opportunities for ministry, it can be challenging to identify which area needs to be your priority.</p>
    <p className="info-text">Which of these 9<span className="more-info">*</span> opportunities is your church's top ministry priority?</p></>);
  
  const defaultText = () => {
    setActiveTile({ active: null })
    setClickedTileContent({ title: "", content: text })
    setInfo(true)
  }

  const showClickedTileInfo = (props) => {
    return (
      props.title ? (
        <>
          <h2>{props?.title}</h2><p>{props?.content}</p>
        </>
      ) : (text)
    )
  }

  return (
    <div className='how-it-works' onMouseLeave={() => defaultText()}>
      <p className="info-text-mobile"><span className="more-info">*</span>Click/hover on the boxes to see how each opportunity is defined.</p> 
      <div className='how-it-works-tiles'>
        {tilesInfoArr.map((item) => {
          return (
            <button type='button' key={item.id} className={`how-it-works-btn ${item.id === activeTile.active ? 'active' : ''}`}
              onClick={() => handleTileClick(item)}
              onMouseEnter={ () => {handleTileClick(item); setInfo(false)}}
              // onMouseLeave={() => handleTileClick(item)}
            >{item.title}</button>)
        })}
      </div>
      <div className='how-it-works-explanation'>
        <div className="explanation-container">
        {showClickedTileInfo(clickedTileContent)}
        <Link to={assessmentLink()} className="test-btn">Take The Test</Link>
        </div>
        {info ? <p className="info-text-desktop"><span className="more-info">*</span>Click/hover on the boxes to see how each opportunity is defined.</p> : null} 
      </div>
    </div>
  )
}

export default HowItWorks;
