import React, { useEffect } from 'react';
import { Link, useSearchParams } from "react-router-dom";

import VideoThumbnail from '../Assets/Images/video-thumb1.jpg';
import PlayButton from '../Assets/Images/play-button.png';
import Clock from '../Assets/Images/clock.png';
import '../App.scss';
import Video from '../Components/Video';

const Start = props => {

  const [isLoading, setIsLoading]         = React.useState(true);

  const [searchParams, setSearchParams]   = useSearchParams();
  
  searchParams.get("leader");

  const howItWorksLink = () => {
    if(searchParams == "") {
      return "/how-it-works";
    } else {
      return "/how-it-works?" + searchParams;
    }
  }

  // const playVideo = (e) => {
  //   console.log(vidRef.current);
  //   vidRef.current.play();
  //   setPlayingVideo(true);
  //   if(!startPlaying) {
  //     setStartPlaying(true);
  //   }
  // }

  // const pauseVideo = (e) => {
  //   console.log(vidRef.current);
  //   vidRef.current.pause();
  //   setPlayingVideo(false);
  // }

  // const showThumbnail = () => {
  //   if(playingVideo) {
  //     return <img className="thumbnail" src={VideoThumbnail} style={{ opacity: '0'}} onClick={(e) => pauseVideo(e)} />;
  //   } else {
  //     if(startPlaying) {
  //       return null
  //     } else {
  //       return <img className="thumbnail" src={VideoThumbnail} />
  //     }
  //   }
  // }

  // const showPlayButton = () => {
  //   if(playingVideo) {
  //     return null
  //   } else {
  //     return <img className="playbtn" src={PlayButton} onClick={(e) => playVideo(e)} />
  //   }
  // }
  const vidsrcURL = "https://40parables-assets.s3.amazonaws.com/bleat-church-fuel-v2.mp4";

  return (
    <>
    <div className="container start">
      <Video vidsrcURL={vidsrcURL} thumbnail={VideoThumbnail} playButton={PlayButton} />
      <div className="intro">
        <h1>What is your top priority in ministry?</h1>
        <h2>Endless ministry opportunities can cause churches to lose sight of their priority.</h2>
        <br />
        
        <h2>Which of the 9 opportunities should be your #1 priority?</h2>
        <div className="completion">
          {/* <Link to={assessmentLink()} className="cta">Get Started</Link> */}
          <Link to={howItWorksLink()} className="cta">Get Started</Link>
          <div className='time-estimate'>
            <img className='clock-icon' src={Clock} alt="" /> <span>3 min</span>
          </div>
        </div>
        {/* <div className="completion">Estimated time for completion: 3 min</div> */}
      </div>
    </div>
    </>
  );
}
export default Start;