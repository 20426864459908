import { Link, useParams } from "react-router-dom";
import Button from '@mui/material/Button';
import testimonialImage from '../Assets/Images/testimonial-churchfuel.jpg';

const Testimonial = () => {

  const { id } = useParams();

  return (
    <>
      <div className="testimonial-container">
        <div className="testimonial">

          <div className="testi">
            <span className="quotation-mark">“</span>Getting your team on board is one of the most important steps in leading any kind of change.  If you want to go one way but your team doesn't agree, it will be hard to get any kind of momentum.  Great people who love the church who don't agree on direction could still be a liability.<span className="quotation-mark bottom"><div className="quotation-block">“</div></span>
          </div>

          <div className="avatar">
            <img src={testimonialImage} alt="pastor" />
          </div>

          <div className="designation">
            Bobby Williams<br />
            <span>Lead Pastor, The Ridge</span>
          </div>
          {/* <div className="cta-btn">
          <Link to={"/invite-team/" + id} target="_blank">
          <Button>DISCOVER YOUR PRIORITY</Button>
          </Link>
        </div> */}

        </div>
      </div>
    </>
  );
}

export default Testimonial;
