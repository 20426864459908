import React from 'react'
import LinearProgress from '@mui/material/LinearProgress';
import Slider from '@mui/material/Slider';


const UrgencyScore = ({ urgencyComponentProps }) => {

  // console.log(urgencyComponentProps);

  const {
    urgencyScore,
    urgencyPercent,
    urgencyVote1,
    urgencyVote2,
    urgencyVote3,
    urgencyVote4,
    urgencyVote5
  } = urgencyComponentProps;

  // let urgencyScore = 45;
  // let urgencyPercent = 50;
  // let urgencyVote1 = 5; 
  // let urgencyVote2 = 1;
  // let urgencyVote3 = 4;
  // let urgencyVote4 = 2;
  // let urgencyVote5 = 3;

  // console.log(urgencyScore, urgencyPercent, urgencyVote1, urgencyVote2, urgencyVote3, urgencyVote4, urgencyVote5);

  // let markerPercentPosition = ((urgencyScore-10)/40) * 100;
  // let markerPercentPosition = (Math.abs(urgencyScore-10)/40) * 100;
  const markerPercentPosition = () => {
    if (urgencyScore - 10 < 0) {
      return 0;
    } else return ((urgencyScore - 10) / 40) * 100;
  }

  return (
    <>
      <div className="urgency">
        <h2>Team Average Urgency Score</h2>
        <div className="urgency-slider">
          <h3>Participant votes</h3>
          <Slider
            defaultValue={urgencyScore}
            valueLabelDisplay="off"
            step={40}
            min={10}
            max={50}
            value={urgencyScore}
            className="custom-slider"
          />
          <div className="slider-points">

            <div className="score" style={{ left: markerPercentPosition() + '%' }}>{(urgencyScore / 10).toFixed(1)}</div>
            {/* <div className="score" style={{ left: urgencyPercent + '%' }}>{urgencyScore / 10}</div> */}
            {/* 78.5 */}
            <div className="top-points" style={{ left: '0%' }}>{urgencyVote1}</div>
            <div className="top-points" style={{ left: '25%' }}>{urgencyVote2}</div>
            <div className="top-points" style={{ left: '50%' }}>{urgencyVote3}</div>
            <div className="top-points" style={{ left: '75%' }}>{urgencyVote4}</div>
            <div className="top-points" style={{ left: '100%' }}>{urgencyVote5}</div>

            <div className="top-labels" style={{ left: '0%' }}></div>
            <div className="top-labels" style={{ left: '25%' }}></div>
            <div className="top-labels" style={{ left: '50%' }}></div>
            <div className="top-labels" style={{ left: '75%' }}></div>
            <div className="top-labels" style={{ left: '100%' }}></div>

            <a className="point" style={{ left: '0%' }}></a>
            <a className="point" style={{ left: '25%' }}></a>
            <a className="point" style={{ left: '50%' }}></a>
            <a className="point" style={{ left: '75%' }}></a>
            <a className="point" style={{ left: '100%' }}></a>

          </div>
          <LinearProgress className="custom-bar" variant="determinate" value={0} />
          <div className="slider-labels">
            <div className="label"><p>Not<br />Urgent</p></div>
            <div className="label" style={{ left: '25%' }}><p>Hardly<br />Urgent</p></div>
            <div className="label" style={{ left: '50%' }}><p>Unsure</p></div>
            <div className="label" style={{ left: '75%' }}><p>Slightly<br />Urgent</p></div>
            <div className="label" style={{ left: '100%' }}><p>Absolutely<br />Urgent</p></div>
          </div>
        </div>
      </div>

      
      {/* This part is only for PDF Export */}
      <h2 className="new-slider-header">Team Average Urgency Score</h2>
      <div className="new-slider-container">

        <div className="custom-html-slider" style={{ position: 'relative' }}>
          <p className="urgency-subtitle">Participants votes</p>

          <div className="custom-slider-container">
            <div className="slider-line"></div>
            <div className="slider-progress" style={{ width: markerPercentPosition() + '%' }}></div>
          </div>
          <div className="slider-points">
            <div className="score" style={{ left: `calc(${markerPercentPosition()}% - 13px)`, position: 'relative' }}>
              {(urgencyScore / 10).toFixed(1)}
            </div>

            <div className="points-and-labels">

              <div className="top-points" style={{ left: '0.5%' }}>{urgencyVote1}</div>
              <div className="top-points" style={{ left: '25.25%' }}>{urgencyVote2}</div>
              <div className="top-points" style={{ left: '50.25%' }}>{urgencyVote3}</div>
              <div className="top-points" style={{ left: '75.25%' }}>{urgencyVote4}</div>
              <div className="top-points" style={{ left: '100.25%' }}>{urgencyVote5}</div>

              <div className="top-labels" style={{ left: '1%' }}></div>
              <div className="top-labels" style={{ left: '25.5%' }}></div>
              <div className="top-labels" style={{ left: '50.5%' }}></div>
              <div className="top-labels" style={{ left: '75.25%' }}></div>
              <div className="top-labels" style={{ left: '100.25%' }}></div>

              <a className="point" style={{ left: '0%' }}><span>&#8226;</span></a>
              <a className="point" style={{ left: '24.75%' }}><span>&#8226;</span></a>
              <a className="point" style={{ left: '49.75%' }}><span>&#8226;</span></a>
              <a className="point" style={{ left: '74.75%' }}><span>&#8226;</span></a>
              <a className="point" style={{ left: '99.75%' }}><span>&#8226;</span></a>

              <div className="label" style={{ left: '-2%' }}><p>Not<br />Urgent</p></div>
              <div className="label" style={{ left: '23%' }}><p>Hardly<br />Urgent</p></div>
              <div className="label" style={{ left: '48%' }}><p>Unsure</p></div>
              <div className="label" style={{ left: '73%' }}><p>Slightly<br />Urgent</p></div>
              <div className="label" style={{ left: '96.5%' }}><p>Absolutely<br />Urgent</p></div>
            
            </div>

          </div>

        </div>

      </div>


    </>
  )
}

export default UrgencyScore;
